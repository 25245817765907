@use "base/mixins" as *;

.button {
  display: grid;
  place-content: center;
  letter-spacing: .5px;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  touch-action: manipulation;
  appearance: none;
  border: none;
  margin: 0;
  width: fit-content;
  height: 56px;
  border-radius: 56px;
  padding: 0 36px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  color: var(--c-white);
  background-size: 200% 100%;
  transition: all 525ms ease;

  @include min(tablet-landscape) {
    font-size: 20px;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-position: 100% 100%;
    }
  }

  &--neon-fuchsia {
    background-image: linear-gradient(to right, #FF00F5, #AE14EA, #FF00F5);
  }

  &--cyber-wave {
    background-image: linear-gradient(to right, #FF00F5, #04B4FF, #FF00F5);
  }

  &--sky-blue {
    background-color: #04ADFF;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #AE14EA;
      }
    }
  }
}
