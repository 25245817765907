@use "base/mixins" as *;

.card-mega {
  position: relative;
  margin: auto;
  max-width: 600px;
  color: var(--c-black);

  @include min(tablet-portrait) {
    display: grid;
    grid-template: 1fr / auto 1fr;
    gap: 0;
    height: 100%;
    max-width: unset;
  }

  &__image {
    position: relative;
    z-index: 1;
    width: fit-content;
    margin: auto;

    img {
      width: 280px;
      height: 280px;

      @include min(tablet-portrait) {
        width: 420px;
        height: 420px;
      }
    }
  }

  &__body {
    position: relative;
    z-index: 1;
    display: grid;
    justify-items: center;
    text-align: center;
    padding: 10px 20px 20px;

    @include min(tablet-portrait) {
      padding: calc(var(--v-offset-sm) / 1.5) var(--v-offset-md) var(--v-offset-sm) 0;
    }

    span.font-weight-bold {
      font-weight: 740;
    }

    p {
      margin: 0 0 10px;
      font-size: 18px;

      @include min(tablet-portrait) {
        font-size: 22px;
      }
    }

    .title-large {
      margin: 0 0 10px;
      font-size: 24px;

      @include min(tablet-portrait) {
        font-size: 30px;
      }
    }

    .button {
      margin: auto;
    }
  }

  &::after {
    content: '';
    position: absolute;
    inset: auto 0 0 0;
    margin: auto;
    width: 100%;
    height: calc(100% - 170px);
    border-radius: 32px;
    background: linear-gradient(-48deg, #E9A840 0%, #FFE56B 100%);
    box-shadow: 0 0 0 4px inset #9611FE;

    @include min(tablet-portrait) {
      inset: 0 0 0 auto;
      width: 74%;
      height: 100%;
    }
  }
}
