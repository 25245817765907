@use "base/mixins" as *;

.widget-front-banner {
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 100vw;
  display: grid;
  justify-items: center;

  @include min(tablet-portrait) {
    max-width: unset;
    overflow: unset;
  }

  &__text {
    position: relative;
    z-index: 1;
    display: grid;
    justify-items: center;
    width: min(100% - 30px, 1440px);

    @include min(tablet-portrait) {
      margin: -100px auto auto;
    }
  }

  .headline-large {
    margin: var(--v-offset-sm) 0;
    width: unset;

    span {
      &:first-child {
        color: var(--c-cyan-blue);
      }

      &:last-child {
        color: var(--c-cyan-blue);
      }
    }
  }

  .button {
    height: 64px;
    border-radius: 64px;
    padding: 0 56px;

    @include min(tablet-portrait) {
      filter: drop-shadow(0 1mm 4mm rgb(160, 0, 210)) drop-shadow(0 0 1mm rgb(160, 0, 210));
    }
  }

  .headline-small {
    margin: var(--v-offset-sm) 0 0;
    width: unset;
  }
}
