:root {
  // z-orders
  --z-index-header: 1;

  // colors
  --c-white: rgb(255 255 255);
  --c-black: rgb(0 0 0);
  --c-midnight-blue: rgb(39 0 109);
  --c-cyan-blue: rgb(107 242 250);
  --c-sky-blue: rgb(255 209 14);
  --c-golden-sand: rgb(227 180 84);

  // offsets
  --v-offset-sm: min(50px, 3vmax);
  --v-offset-md: min(100px, 5vmax);
  --v-offset-lg: min(160px, 8vmax);

  // NPM package typographics
  --max-vw: 1600;
  --min-vw: 600;
  --max-fs: 1;
  --min-fs: .6;
  --font-family-base: "Montserrat", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --line-height-base: 1.5;
  --line-height-heading: 1.3em;

  // scrollbar colors
  --c-scrollbar-track: var(--c-midnight-blue);
  --c-scrollbar-thumb: var(--c-cyan-blue);
  --c-scrollbar-hover: var(--c-sky-blue);

  @media (width >= 992px) {
    --v-offset-sm: min(40px, 2vmax);
    --v-offset-md: min(80px, 4vmax);
  }
}
