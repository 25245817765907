.headline-large {
  width: min(100% - 30px, 960px);
  max-inline-size: none;
  font-size: 6.2em;
  font-weight: 700;
  text-align: center;
  color: var(--c-cyan-blue);
}

.headline-medium {
  width: min(100% - 30px, 960px);
  max-inline-size: none;
  font-size: 4.8em;
  font-weight: 700;
  text-align: center;
  color: var(--c-cyan-blue);
}

.headline-small {
  max-inline-size: none;
  font-size: 3.2em;
  font-weight: 300;
  text-align: center;
}

.title-large {
  font-size: 3em;
  font-weight: 600;
}
