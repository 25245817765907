@use "base/mixins" as *;

.widget-prize-draw {
  width: min(100% - 30px, 960px);
  padding: calc(var(--v-offset-sm) / 2) 0 var(--v-offset-sm);
  transform: translateY(30px);

  @include min(tablet-landscape) {
    transform: translateY(40px);
  }
}
