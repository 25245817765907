body {
  &::-webkit-scrollbar {
    width: 12px;
    opacity: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--c-scrollbar-thumb, var(--c-canary-yellow));
    border: 4px solid var(--c-scrollbar-track, var(--c-blue));
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--c-scrollbar-hover, var(--c-text-greenish-yellow));
  }

  &::-webkit-scrollbar-track {
    background-color: var(--c-scrollbar-track, var(--c-blue));
  }
}
